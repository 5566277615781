
import { Component, Vue, Prop } from "vue-property-decorator";
import { InvitationInfo } from "./../components/interfaces";

import ConfirmSessionForm from "./../components/forms/ConfirmSessionForm.vue";
import Register from "./Register.vue";
import Login from "./Login.vue";
import InvitationConfirm from "@/components/InvitationConfirm.vue";
import { APIError } from "../services/http";

@Component({
	components: {
		ConfirmSessionForm,
		InvitationConfirm,
		Register,
		Login,
	},
})
export default class Invitation extends Vue {
	@Prop({ default: () => ({}) })
	public query!: Record<string, any>;

	@Prop({ required: true })
	public info!: InvitationInfo;

	@Prop()
	public error?: APIError;

	public returnUrl: string = process.env.VUE_APP_HOME_URL as string;

	public action = this.$url(process.env.VUE_APP_CONFIRM_SESSION_ACTION as string, this.query);

	created() {
		if (this.query.continue !== undefined) {
			this.returnUrl = this.query.continue;
		}

		console.log("Return url", this.returnUrl);

		console.log("Return url is internal? ", this.$isInternalUrl(this.returnUrl));
	}

	onConfirmDone({ data }: any) {
		return this.$redirect(data.next ?? this.returnUrl, true);
	}
}
