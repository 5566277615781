
import { Component, Vue, Prop } from "vue-property-decorator";
import LoginForm from "./../components/forms/LoginForm.vue";
import { Invitation } from "./../components/interfaces";

@Component({
	components: {
		LoginForm,
	},
})
export default class Login extends Vue {
	@Prop({ default: () => ({}) })
	public query!: Record<string, any>;

	@Prop({ required: true })
	public defaultCountry!: string;

	@Prop({ required: true })
	public countries!: string[];

	@Prop()
	public invitation?: Invitation;

	public returnUrl: string = process.env.VUE_APP_HOME_URL as string;

	public action = this.$url(process.env.VUE_APP_SIGNIN_ACTION as string, this.fullQueryString);

	get signupUrl() {
		return this.$url(process.env.VUE_APP_SIGNUP_URL as string, this.fullQueryString);
	}

	public get fullQueryString() {
		if (this.invitation) {
			// eslint-disable-next-line @typescript-eslint/camelcase
			return { ...this.query, ...{ invitation_id: this.invitation.id } };
		}
		return this.query;
	}

	created() {
		if (this.query.continue !== undefined) {
			this.returnUrl = this.query.continue;
		}
	}

	onLoginDone({ data }: any) {
		this.$store.commit("SET_SIGNEDIN_STATUS", true);
		this.$store.commit("SET_CURRENT_USER", data.user);
		const next = data.next ? data.next : this.$isInternalUrl(this.returnUrl) ? this.returnUrl : process.env.VUE_APP_HOME_URL;

		return this.$redirect(next, true);
		//return this.$redirect(data.next ?? this.returnUrl, true);
	}
}
