
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { AlertComponentInterface, Invitation } from "../components/interfaces";
import { LazyLoad } from "./../components/LazyLoad";

type RegisterStep = "register" | "verify" | "setup" | "complete" | "error";

@Component({
	components: {
		RegisterForm: () => LazyLoad(import(/* webpackChunkName: "register.form" */ "./../components/forms/RegisterForm.vue")),
		VerifyForm: () => LazyLoad(import(/* webpackChunkName: "verify.form" */ "./../components/forms/VerifyForm.vue")),
		ChallengeForm: () => LazyLoad(import(/* webpackChunkName: "challenge.form" */ "./../components/forms/ChallengeForm.vue")),
		ProfileSetupForm: () => LazyLoad(import(/* webpackChunkName: "profile-setup-form" */ "./../components/forms/ProfileSetupForm.vue")),
		SuccessRedirect: () => LazyLoad(import(/* webpackChunkName: "success-redirect" */ "./../components/SuccessRedirect.vue")),
	},
})
export default class Register extends Vue {
	@Prop({ default: () => ({}) })
	public query!: Record<string, any>;

	@Prop({ required: true })
	public defaultCountry!: string;

	@Prop({ required: true })
	public countries!: string[];

	@Prop()
	public invitation?: Invitation;

	public actions = {
		begin: this.$url(process.env.VUE_APP_SIGNUP_BEGIN_ACTION as string, this.fullQueryString),
		complete: this.$url(process.env.VUE_APP_SIGNUP_COMPLETE_ACTION as string, this.fullQueryString),
		verify: process.env.VUE_APP_CHALLENGE_VERIFY_ACTION,
		setup: process.env.VUE_APP_PROFILE_SETUP_ACTION,
	};

	public get fullQueryString() {
		if (this.invitation) {
			// eslint-disable-next-line @typescript-eslint/camelcase
			return { ...this.query, ...{ invitation_id: this.invitation.id } };
		}
		return this.query;
	}

	@Ref("alert")
	public readonly $alert!: AlertComponentInterface;

	@Ref("success")
	public readonly $success!: InstanceType<any>;

	@Ref("registerForm")
	public readonly $registerForm!: InstanceType<any>;

	@Ref("verifyForm")
	public readonly $verifyForm!: InstanceType<any>;

	public step: RegisterStep = "register";
	public result: Record<"begin" | "verification" | "complete", any> = {
		begin: {},
		verification: {},
		complete: {},
	};

	get signinUrl() {
		return this.$url(process.env.VUE_APP_SIGNIN_URL as string, this.query);
	}

	get returnUrl() {
		return this.query.continue ?? process.env.VUE_APP_HOME_URL;
	}

	get verifyTitle() {
		switch (this.result.begin.instrument) {
			case "phone":
				return "Verify Your Phone Number";
			case "email":
				return "Verify Your Email";
			default:
				return "Verify Account";
		}
	}

	onRegistrationDone({ data }: any) {
		this.result.begin = data;

		this.step = "verify";
	}

	onVerificationDone({ data }: any) {
		this.result.verification = data;
		this.completeRegistration();
	}

	onChallengeCancel() {
		this.step = "register";
		this.$registerForm.recreatePuzzle();
	}

	onChallengeRetry() {
		this.$registerForm.resubmit();
	}

	async completeRegistration() {
		const response = await this.$api.post(this.actions.complete, {
			key: this.result.begin.key,
			principal: this.result.begin.principal,
			// eslint-disable-next-line @typescript-eslint/camelcase
			challenge_id: this.result.begin.challenge.cid,
			// eslint-disable-next-line @typescript-eslint/camelcase
			verification_token: this.result.verification.token,
		});

		if (response.ok) {
			const data = response.data;
			this.$store.commit("SET_SIGNEDIN_STATUS", true);
			this.$store.commit("SET_CURRENT_USER", data.user);
			this.$verifyForm.$alert.clear();
			const next = data.next ? data.next : this.$isInternalUrl(this.returnUrl) ? this.returnUrl : process.env.VUE_APP_HOME_URL;

			return this.$redirect(next, true);
		} else if (response.validationFailed) {
			// do something
			const errors = response.validationErrors;
			this.$verifyForm.$formObserver.setErrors(errors);
			this.$verifyForm.$alert.addAlert({
				type: "danger",
				message: "Invalid verification code",
			});
		} else {
			this.step = "error";
			this.$alert.addAlert({
				type: "danger",
				message: response.error.message,
			});
		}
	}

	onSetupDone({ data }: any) {
		this.$store.commit("SET_CURRENT_USER", data.user);
		this.showComplete();
	}

	showComplete() {
		this.step = "complete";
		this.$success.count();
	}
}
