
import { Component, Watch, Prop } from "vue-property-decorator";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { BaseForm } from "./BaseForm";
import { Invitation } from "../interfaces";
import InvitationSummary from "../InvitationSummary.vue";

@Component({
	components: {
		VuePhoneNumberInput,
		InvitationSummary,
	},
})
export default class LoginForm extends BaseForm {
	public labels: Record<string, string> = {
		phone: "Phone Number",
		email: "Email",
		password: "Password",
		confirmPassword: "Confirm Password",
	};
	public phoneInput = "";
	@Prop({ default: "phone" })
	public instrument!: string;

	@Prop()
	public invitation?: Invitation;

	// public altMode = "email";

	@Prop({ required: true, default: "NG" })
	public defaultCountry!: string;

	@Prop({ required: true, default: [] })
	public countries!: string[];

	@Prop({ required: true })
	public signupUrl!: string;

	public model: Record<string, any> = {
		instrument: this.instrument,
		phone: null,
		country: this.defaultCountry,
		email: null,
		password: null,
		puzzle: null,
	};

	public subjectLocked = false;

	public mounted() {
		if (this.invitation) {
			this.subjectLocked = true;
			this.model.instrument = this.instrument = this.invitation.subject.instrument;
			if (this.instrument === "email") {
				this.model.email = this.invitation.subject.value;
			} else if (this.instrument === "phone") {
				this.model.phone = this.phoneInput = this.invitation.subject.value;
			}
		}
	}

	@Watch("instrument")
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public watchInstrument(newValue: string) {
		// this.altMode = oldValue;
		this.model.instrument = newValue;
	}

	public get altInstrument() {
		if (this.instrument === "email") {
			return "phone";
		}
		return "email";
	}

	public get forgotUrl() {
		const entry = this.$url(`${window.location.pathname}${window.location.search}`);
		return this.$url("/account/password/forgot", {
			continue: entry,
			referrer: entry,
		});
	}

	public onPhoneNumberUpdate(payload: any) {
		this.model.phone = payload.formattedNumber;
		this.model.country = payload.countryCode;
	}

	public empty(exclude: string[] = []) {
		Object.keys(this.model).forEach((key) => {
			if (exclude.includes(key)) {
				return;
			}
			this.model[key] = null;
		});
		this.model.instrument = this.instrument;
		this.model.country = this.defaultCountry;
	}

	public toggle(e: any) {
		e.preventDefault();
		this.instrument = this.altInstrument;
		this.empty(["puzzle"]);
		this.$nextTick(() => {
			this.$formObserver.reset();
		});
	}

	public async send() {
		const response = await this.$api.post(this.action, this.model, {
			headers: this.invitation ? { "X-Subject-Hash": this.invitation.subject.hash } : {},
		});
		if (!response.ok) {
			return this.handleError(response);
		}
		this.$alert.clear();
		this.$alert.addAlert({
			type: "success",
			message: response.message,
		});
		this.$emit("done", response.body);
	}
}
