
import { Component, Prop } from "vue-property-decorator";
import { BaseForm } from "./forms/BaseForm";
import { Invitation } from "./interfaces";
import InvitationSummary from "./InvitationSummary.vue";
import SuccessRedirect from "./SuccessRedirect.vue";
import format from "format-util";

@Component({
	components: {
		InvitationSummary,
		SuccessRedirect,
	},
})
export default class InvitationConfirm extends BaseForm {
	@Prop({ required: false })
	public declare readonly action: string;

	@Prop({ required: true })
	invitation!: Invitation;

	@Prop({ required: false })
	accept?: "auto";

	@Prop({ required: true })
	prompt!: "switch" | "confirm";

	@Prop()
	public state!: string;

	public done = false;

	public next = "";

	public get message() {
		return `You have joined ${this.invitation.creator.displayName} on the ${this.invitation.realm.name}`;
	}

	get signoutUrl() {
		return this.$url(process.env.VUE_APP_SIGNOUT_URL as string, { state: this.state });
	}

	get startUrl() {
		return format(process.env.VUE_APP_INVITATION_START_URL ?? "/account/invitation/%s/get-started", this.invitation.id);
	}

	public mounted() {
		if (this.invitation.status === "accepted") {
			if (this.invitation.canNext) {
				this.next = this.startUrl;
			}
			this.done = true;
		}
		if (!this.done && this.accept && this.accept === "auto") {
			this.submit();
		}
	}

	public async send() {
		const response = await this.$api.post(`/account/invitation/${this.invitation.id}/accept`, {});
		if (!response.ok) {
			return this.handleError(response);
		}
		if (this.$listeners.done) {
			this.$emit("done", response.body);
		} else {
			this.next = response.data.canNext ? this.startUrl : "";
		}
		this.done = true;
	}
}
